<template>
 
  <div class="main" >
    <router-view />
  </div>
</template>

<script>
// import ShadowIndex from '../ShadowIndex/ShadowIndex'
// import ShadowComposer from '../ShadowComposer/ShadowComposer'
import { defineComponent, ref, watch } from "vue";
import { useRouter } from "vue-router";
export default defineComponent({
  name: "TgLayoutMain",
  // components: {
  // 	ShadowIndex,
  // 	ShadowComposer
  // },
  setup() {
    const router = useRouter();
    const nav_url = ref("/");
    const getUrl = function (value) {
      nav_url.value = value;
      router.push({
        path: nav_url.value,
      });
    };
    let NavType = ref(2);
    watch(
      () => router.currentRoute.value.path,
      (newData, oldData) => {
        if (
          newData == "/" ||
          newData == "/ShadowComposer" ||
          newData == "/ShadowMaterial" ||
          newData == "/ShadowBusiness" ||
          newData == "/ShadowEquipment" ||
          newData == "/ShadowTrain" || 
          newData == "/ShadowIndexAllWorks"

        ) {
          NavType.value = 2;
        } else {
          NavType.value = 1;
        }
        if (newData != oldData) {
          window.scrollTo(0, 0);
        }
      },
      {
        immediate: false,
        deep: true,
      }
    )
    return {
      nav_url,
      getUrl,
      router,
      NavType,
      // getNavType,
	//   watch
    };
  },
});
</script>

<style scoped>
.main {
  flex-grow: 1;
  width: 100%;
  /* min-width:12.8rem; */
  /* margin:0 auto; */
}
</style>
