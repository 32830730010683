<template>
  <div class="body_" :style="moduleShareBoolean ? 'overflow-y:hidden;' : ''">
    <!-- 部分的测试阶段 -->
     <!-- <LayouHeader></LayouHeader> -->
    <!-- <moduleShare class="module_share" v-if="moduleShareBoolean"></moduleShare> -->
    <TgLayoutHeader
      ref="TgLayoutHeaderRef"
      :class="navType == 2 ? 'TgLayoutHeader1' : 'TgLayoutHeader2'"
      @NavUrlFun="NavUrlFun"
      @setloginType="setloginType"
      @TgLoginType="TgLoginType"
      @navTypeFun="navTypeFun"
    >
    </TgLayoutHeader>
    <TgLayoutMain ref="RefTgLayoutMain"></TgLayoutMain>
    <TgLayoutFloor></TgLayoutFloor>

    <!-- <mini-audio audio-source="https://example.com/example.mp3"></mini-audio> -->
    <TgLogin
      class="TgLogin"
      @TgLoginType="TgLoginType"
      @setloginType="setloginType"
      @loginTypeFun="loginTypeFun"
      v-if="TgLoginBoolean == true"
    >
    </TgLogin>
  </div>
</template>

<script>
import { defineComponent, ref, watch, onMounted, nextTick } from "vue";
import TgLayoutHeader from "./TgLayout/TgLayoutHeader.vue";
// import LayouHeader from "./TgLayout/LayouHeader.vue";
import TgLayoutMain from "./TgLayout/TgLayoutMain.vue";
import TgLayoutFloor from "./TgLayout/TgLayoutFloor.vue";
import TgLogin from "./TgLogin/TgLogin.vue";
// import moduleShare from "../components/moduleShare.vue"
export default defineComponent({
  name: "TgIndex",
  components: {
    TgLayoutHeader,
    // LayouHeader,
    TgLayoutMain,
    TgLayoutFloor,
    TgLogin,
    // moduleShare
  },
  setup(props, context) {
    const TgLayoutHeaderRef = ref(null);
    let loginType = ref(localStorage.getItem("token") ? true : false);
    let setloginType = function (index) {
      loginType.value = index;
    };
    onMounted(() => {
      nextTick(() => {
        TgLayoutHeaderRef.value.setLoginState(loginType.value);
      });
    });
    watch(loginType, (newName, oldName) => {
      TgLayoutHeaderRef.value.setLoginState(loginType.value);
    });
    const loginTypeFun = function (index) {
      loginType.value = index;
    };
    const RefTgLayoutMain = ref();
    // const funA = function () {}
    let nav_url = ref("");
    const NavUrlFun = function (value) {
      RefTgLayoutMain.value.getUrl(value);
    };
    /* 登录组组件状态 */
    const TgLoginBoolean = ref("false");
    const TgLoginType = function (index) {
      TgLoginBoolean.value = index;
    };
    /* 分享组件 */
    // const moduleShareBoolean = ref(true)
    let navType = ref(1); // 1main无高度 , 2main0.7rem
    if (!localStorage.getItem("NavType")) {
      localStorage.setItem("NavType", 1);
      navType.value = localStorage.getItem("NavType");
    } else {
      navType.value = localStorage.getItem("NavType");
    }
    let navTypeFun = function (index) {
      console.log(index);
      navType.value = index;
      // console.log(RefTgLayoutMain.value.getNavType)
      if (RefTgLayoutMain.value.getNavType) {
        RefTgLayoutMain.value.getNavType(index);
      }
    };
    return {
      nav_url,
      RefTgLayoutMain,
      NavUrlFun,
      TgLoginBoolean,
      TgLoginType,
      loginType,
      loginTypeFun,
      setloginType,
      TgLayoutHeaderRef,
      // LayouHeaderRef,
      navTypeFun,
      navType,
      // moduleShareBoolean
    };
  },
  methods: {},
});
</script>

<style scoped lang="less">
.TgLogin {
  z-index: 11;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.body_ {
  display: flex;
  flex-direction: column;
  position: relative;
  max-height: 100vh;
}

.TgLayoutHeader1 {
  background: #fff;
}

.TgLayoutHeader2 {
  background: rgba(0, 0, 0, 0.6);
}
</style>
